/*
TypeTogether Webfont Licence Agreement, version 2.0
© Copyright TypeTogether 2020. All Rights Reserved
*/
@font-face {
    font-family: 'Soleil';
    src: local('Soleil-Light'), url(./../fonts/Soleil-Light.woff) format('woff');
}

@font-face {
    font-family: 'Soleil';
    font-weight: 700;
    src: local('Soleil-Bold'), url(./../fonts/Soleil-Bold.woff) format('woff');
}

html,
body {
    height: 100%;
}

body {
    font-family: 'Soleil', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

#root {
    display: flex;
    min-height: 100%;
    flex-direction: column;
}

.content {
    flex: 1 0 auto;
    padding-top: 122px;
    padding-bottom: 0;
    background: #2b2d42;
}

.header {
    background: #2b2d42;
    position: fixed;
    z-index: 1;
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 68px;
}

.ant-menu, .ant-menu-sub, .ant-menu-inline {
    color: white;
    background-color: #2b2d42 !important;
    border-bottom: 0;
    font-size: 20pt;
}

.ant-menu-submenu > .ant-menu {
    background-color: #2b2d42 !important;
}

.ant-menu-item-active {
    background-color: #2b2d42 !important;
}

.ant-menu-submenu-title{
    color: white !important;
}

.ant-menu-inline {
    border: none;
}

.ant-menu-item:hover {
    color: #FFD151 !important;
    border-bottom: 2px solid #FFD151 !important;
}

.ant-menu-item a {
    color: white !important;
}

.ant-menu-item-group-title {
    color: #FFD151;
    font-size: 14pt;
}

.ant-menu-item a:hover {
    color: #FFD151 !important;
}

.ant-menu-submenu-title:hover{
    color: #FFD151 !important;
}

.ant-menu-submenu-arrow {
    color: white;
}

.ant-menu-submenu-active {
    background-color: #2b2d42  !important;
    color: #FFD151 !important;
    border-bottom: 2px solid #FFD151 !important;
}

.ant-menu-horizontal > .ant-menu-item::after,
.ant-menu-horizontal > .ant-menu-submenu::after {
    border-bottom: none !important;
    transition: none !important;
}

.footer {
    background: #2b2d42;
}

.footer-signup {
    background: #F2F2F2;
}

.h4-footer {
    color: #FFD151;
}

.p-footer {
    color: white;
}

.a-footer {
    color: white;
}

.a-footer:link {
    color: white;
    background-color: transparent;
    text-decoration: none;
}

.a-footer:visited {
    color: white;
    background-color: transparent;
    text-decoration: none;
}

.a-footer:hover {
    color: #FFD151;
    background-color: transparent;
    text-decoration: underline;
}

.a-footer:active {
    color: #FF824A;
    background-color: transparent;
    text-decoration: underline;
}

.footer-copyright {
    background: #2b2d42;
    color: white;
}

p {
    white-space: pre-line;
    text-align: justify;
}

@media only screen and (min-width: 768px) {
    .h1-carousel {
        color: #FFD151;
        text-align: left;
        font-size: 40pt;
    }

    .h1-content {
        text-align: left;
        color: black;
        width: 100%;
        font-size: 40pt;
        font-weight: bold;
    }

    .h1-centered {
        padding-bottom: 20px;
        text-align: center;
        color: black;
        width: 100%;
        font-size: 40pt;
        font-weight: bold;
    }

    .h2-centered {
        padding-bottom: 20px;
        text-align: center;
        color: black;
        width: 100%;
        font-size: 32pt;
    }

    .h2-content {
        padding-bottom: 20px;
        text-align: left;
        color: black;
        width: 100%;
        font-size: 32pt;
    }

    .h3-content {
        text-align: left;
        color: black;
        width: 100%;
        font-size: 24pt;
    }

    .h3-card {
        text-align: left;
        width: 100%;
        font-size: 24pt;
        font-weight: bold;
        color: #FFD151;
        margin-bottom: -5px;
    }

    .h4-card {
        text-align: left;
        width: 100%;
        font-size: 16pt;
    }

    .p-carousel {
        color: white;
        white-space: pre-line;
        text-align: left;
        font-size: 24pt;
    }

    .p-carousel-2 {
        color: black;
        white-space: pre-line;
        text-align: left;
        font-size: 24pt;
    }

    .p-content {
        white-space: pre-line;
        text-align: justify;
        font-size: 20pt;
        color: black;
    }

    .p-content2 {
        white-space: pre-line;
        text-align: center;
        font-size: 20pt;
        color: black;
    }

    .p-content3 {
        white-space: pre-line;
        text-align: left;
        font-size: 20pt;
        color: black;
    }

    .p-card {
        white-space: pre-line;
        text-align: left;
        font-size: 14pt;
        color: black;
    }

    .p-bio {
        white-space: pre-line;
        text-align: left;
        font-size: 16pt;
        color: black;
    }
}

@media only screen and (max-width: 768px) {
    .h1-carousel {
        color: #FFD151;
        text-align: left;
        font-size: 32pt;
    }

    .h1-content {
        text-align: left;
        color: black;
        width: 100%;
        font-size: 32pt;
        font-weight: bold;
    }

    .h1-centered {
        padding-bottom: 20px;
        text-align: center;
        color: black;
        width: 100%;
        font-size: 32pt;
        font-weight: bold;
    }

    .h2-centered {
        padding-bottom: 20px;
        text-align: center;
        color: black;
        width: 100%;
        font-size: 24pt;
    }

    .h2-content {
        padding-bottom: 20px;
        text-align: left;
        color: black;
        width: 100%;
        font-size: 24pt;
    }

    .h3-content {
        text-align: left;
        color: black;
        width: 100%;
        font-size: 16pt;
    }

    .h3-card {
        text-align: left;
        width: 100%;
        font-size: 16pt;
        font-weight: bold;
        color: #FFD151;
        margin-bottom: -5px;
    }

    .h4-card {
        text-align: left;
        width: 100%;
        font-size: 14pt;
    }

    .p-carousel {
        color: white;
        white-space: pre-line;
        text-align: left;
        font-size: 16pt;
    }

    .p-carousel-2 {
        color: black;
        white-space: pre-line;
        text-align: left;
        font-size: 16pt;
    }

    .p-content {
        white-space: pre-line;
        text-align: justify;
        font-size: 16pt;
        color: black;
    }

    .p-content2 {
        white-space: pre-line;
        text-align: center;
        font-size: 16pt;
        color: black;
    }

    .p-content3 {
        white-space: pre-line;
        text-align: left;
        font-size: 16pt;
        color: black;
    }

    .p-card {
        white-space: pre-line;
        text-align: left;
        font-size: 12pt;
        color: black;
    }

    .p-bio {
        white-space: pre-line;
        text-align: left;
        font-size: 14pt;
        color: black;
    }
}

.header-logo {
    float: left;
    padding-left: 0;
    padding-bottom: 10px;
    display: block;
    margin: 24px 24px 24px 12.5%;
    width: 320px;
}

@media only screen and (max-width: 576px) {
    .header-logo {
        float: left;
        padding-left: 0;
        padding-bottom: 10px;
        display: block;
        margin: 40px 24px 24px 12.5%;
        width: 200px;
    }
}

.ant-carousel .slick-prev,
.ant-carousel .slick-next {
    display: block;
    position: absolute;
    top: 45%;
    margin-left: -20px;
    color: #2b2d42;
    font-size: 30pt;
    background: transparent;
}

.ant-carousel-home .slick-prev,
.ant-carousel-home .slick-next {
    color: #F2F2F2;
    top: 50%;
}


.ant-carousel .slick-prev:hover,
.ant-carousel .slick-next:hover {
    color: #FFD151;
}

.ant-carousel .slick-prev:focus,
.ant-carousel .slick-next:focus {
    color: #FF824A;
}

.carousel-box {
    height: 100%;
    background: #2b2d42;
}

.img-centered {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 60%;
}

.img-centered-carousel {
    width: 100%;
}

.h1-footer {
    color: white;
    font-size: 18pt;
    text-align: center;
}

.ant-btn-primary {
    color: white !important;
    border-color: white !important;
    background-color: #2b2d42 !important;
    font-size: 14pt;
    font-weight: bold;
    height: 40pt;
    border-radius: 10px;
}


.ant-btn-primary:hover {
    color: #2b2d42 !important;
    border-color: #FFD151 !important;
    background-color: #FFD151 !important;
}

.btn-download {
    color: black;
    border-color: black;
    background-color: #F2F2F2;
    font-size: 12pt;
}

.btn-download:focus {
    color: black;
    border-color: black;
    background-color: #F2F2F2;
    font-size: 12pt;
}

.btn-download:hover {
    color: white;
    border-color: #2b2d42;
    background-color: #2b2d42;
    font-size: 12pt;
}

.btn-signup {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 30%;
    color: black;
    border-color: black;
    background-color: #F2F2F2;
    font-size: 12pt;
    font-weight: bold;
}

.btn-signup:focus {
    color: black;
    border-color: black;
    background-color: #F2F2F2;
    font-size: 12pt;
}

.btn-signup:hover {
    color: white;
    border-color: #2b2d42;
    background-color: #2b2d42;
    font-size: 12pt;
}

.btn-enroll {
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: 100px;
    width: 30%;
    color: black;
    border-color: #FFD151;
    background-color: #FFD151;
    font-size: 20pt;
}

.btn-enroll:focus {
    color: black;
    border-color: #FFD151;
    background-color: #FFD151;
    font-size: 20pt;
}

.btn-enroll:hover {
    color: black;
    border-color: white;
    background-color: #FFD151;
    font-size: 20pt;
}

.ant-radio-button-wrapper {
    font-size: 14pt;
}

.events {
    margin: 0;
    padding: 0;
    list-style: none;
}
.events .ant-badge-status {
    width: 100%;
    overflow: hidden;
    font-size: 12px;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.notes-month {
    font-size: 28px;
    text-align: center;
}
.notes-month section {
    font-size: 28px;
}

.ul-custom {
    list-style: none; /* Remove default bullets */
}

.ul-custom li::before {
    content: "\2713\0020";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
    color: #FFD151; /* Change the color */
    font-weight: bold; /* If you want it to be bold */
    display: inline-block; /* Needed to add space between the bullet and the text */
    width: 1.5em; /* Also needed for space (tweak if needed) */
    margin-left: -1.5em; /* Also needed for space (tweak if needed) */
}
